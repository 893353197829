var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "liveinfo-form", attrs: { "no-body": "" } }, [
        _c(
          "div",
          { staticClass: "m-2" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                    attrs: { md: "10" },
                  },
                  [
                    _c("v-select", {
                      staticClass: "el-def",
                      staticStyle: { width: "350px" },
                      attrs: {
                        options: _vm.codes.clients,
                        placeholder: "제약사",
                        reduce: function (option) {
                          return option.code
                        },
                      },
                      model: {
                        value: _vm.query.clientId,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "clientId", $$v)
                        },
                        expression: "query.clientId",
                      },
                    }),
                    _c("b-form-input", {
                      staticClass: "el-def",
                      attrs: { placeholder: "제목" },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.fetchData()
                        },
                      },
                      model: {
                        value: _vm.query.title,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "title", $$v)
                        },
                        expression: "query.title",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-1" },
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                    attrs: { md: "10" },
                  },
                  [
                    _c("v-select", {
                      staticClass: "el-def",
                      attrs: {
                        options: _vm.codes.webSeminarStatus,
                        placeholder: "진행 상태",
                        reduce: function (option) {
                          return option.code
                        },
                      },
                      model: {
                        value: _vm.query.webSeminarStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "webSeminarStatus", $$v)
                        },
                        expression: "query.webSeminarStatus",
                      },
                    }),
                    _c("b-form-datepicker", {
                      staticClass: "el-def",
                      attrs: { placeholder: "진행기간(Fr)" },
                      model: {
                        value: _vm.query.startDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "startDate", $$v)
                        },
                        expression: "query.startDate",
                      },
                    }),
                    _c("label", { staticClass: "ml-25 mr-50" }, [_vm._v("~")]),
                    _c("b-form-datepicker", {
                      staticClass: "el-def",
                      attrs: { placeholder: "진행기간(To)" },
                      model: {
                        value: _vm.query.endDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "endDate", $$v)
                        },
                        expression: "query.endDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-end",
                    attrs: { md: "2" },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-search",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.fetchData()
                          },
                        },
                      },
                      [_vm._v(" 조회 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "m-2" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start",
                      attrs: { md: "6" },
                    },
                    [
                      _c("h5", { staticClass: "mb-0 ml-50" }, [
                        _vm._v(
                          " 웹세미나 리스트 [" +
                            _vm._s(_vm.totalRecords) +
                            "] 건 "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-end",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-search",
                          attrs: { variant: "outline-dark" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.excelDownload()
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-50",
                            attrs: { icon: "DownloadIcon" },
                          }),
                          _c("span", [_vm._v("다운로드")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            ref: "refSeminarTable",
            staticClass: "position-relative",
            attrs: {
              "primary-key": "webSeminarId",
              items: _vm.fetchRecords,
              fields: _vm.tableColumns,
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              responsive: "",
              "sticky-header": "500px",
              selectable: "",
              "select-mode": "single",
            },
            on: {
              "update:sortBy": function ($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function ($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function ($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function ($event) {
                _vm.sortDesc = $event
              },
              "row-selected": _vm.onSelectedRecord,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(seminarDate)",
                fn: function (data) {
                  return [
                    _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("$dateFormatter")(
                              data.item.startDate,
                              "YYYY-MM-DD"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "cell(startTime)",
                fn: function (data) {
                  return [
                    _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("$dateFormatter")(
                              data.item.startDate,
                              "HH:mm"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "cell(endTime)",
                fn: function (data) {
                  return [
                    _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("$dateFormatter")(data.item.endDate, "HH:mm")
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "cell(isStart)",
                fn: function (data) {
                  return [
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            data.item.isCancel
                              ? "취소"
                              : data.item.isStart
                              ? "완료"
                              : "예정"
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("b-pagination", {
            attrs: {
              "total-rows": _vm.totalRecords,
              limit: "10",
              "per-page": "10",
              align: "center",
            },
            model: {
              value: _vm.currPage,
              callback: function ($$v) {
                _vm.currPage = $$v
              },
              expression: "currPage",
            },
          }),
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "mt-2" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-start",
                  attrs: { md: "6" },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "EditIcon", size: "19" },
                  }),
                  _c("h4", { staticClass: "mb-0 ml-50" }, [_vm._v(" 상세 ")]),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center justify-content-end",
                  attrs: { md: "6" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.resetRecord()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        attrs: { icon: "PlusSquareIcon" },
                      }),
                      _c("span", [_vm._v("신규")]),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.saveRecord()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        attrs: { icon: "SaveIcon" },
                      }),
                      _c("span", [_vm._v("저장")]),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: { variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.removeRecord()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        attrs: { icon: "Trash2Icon" },
                      }),
                      _c("span", [_vm._v("삭제")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "validation-observer",
            { ref: "webSeminarFormRules" },
            [
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "제약사명",
                            "label-for": "clinet-name",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "제약사명", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-select", {
                                      attrs: {
                                        id: "clinet-name",
                                        options: _vm.codes.clients,
                                        reduce: function (option) {
                                          return option.code
                                        },
                                        state: errors.length > 0 ? false : null,
                                        disabled: _vm.record.isCancel,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.fetchClientInfo()
                                        },
                                      },
                                      model: {
                                        value: _vm.record.clientId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.record, "clientId", $$v)
                                        },
                                        expression: "record.clientId",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "포털명",
                            "label-for": "clinet-portal-name",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "clinet-portal-name",
                              disabled: _vm.record.isCancel,
                              readonly: "",
                            },
                            model: {
                              value: _vm.recordSub.clientPortalName,
                              callback: function ($$v) {
                                _vm.$set(_vm.recordSub, "clientPortalName", $$v)
                              },
                              expression: "recordSub.clientPortalName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "제목", "label-for": "title" } },
                        [
                          _c("validation-provider", {
                            attrs: { name: "제목", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "title",
                                        state: errors.length > 0 ? false : null,
                                        disabled: _vm.record.isCancel,
                                        maxlength: "150",
                                      },
                                      model: {
                                        value: _vm.record.title,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.record,
                                            "title",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "record.title",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "연자명",
                            "label-for": "lecturer-name",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "연자명", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "lecturer-name",
                                        state: errors.length > 0 ? false : null,
                                        disabled: _vm.record.isCancel,
                                        maxlength: "200",
                                        placeholder: "연자명을 입력하세요",
                                      },
                                      model: {
                                        value: _vm.record.lecturerName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.record,
                                            "lecturerName",
                                            typeof $$v === "string"
                                              ? $$v.trim()
                                              : $$v
                                          )
                                        },
                                        expression: "record.lecturerName",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "연자소속 병/의원명",
                            "label-for": "lecturer-work-place",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "lecturer-work-place",
                              disabled: _vm.record.isCancel,
                              maxlength: "200",
                              placeholder: "병/의원명을 입력하세요",
                            },
                            model: {
                              value: _vm.record.lecturerWorkPlace,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.record,
                                  "lecturerWorkPlace",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "record.lecturerWorkPlace",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "연자 진료과",
                            "label-for": "lecturer-medical-dept",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "lecturer-medical-dept",
                              disabled: _vm.record.isCancel,
                              maxlength: "200",
                              placeholder: "진료과를 입력하세요",
                            },
                            model: {
                              value: _vm.record.lecturerMedicalDept,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.record,
                                  "lecturerMedicalDept",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "record.lecturerMedicalDept",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "진행 일자",
                            "label-for": "seminar-date",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "진행 일자", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-datepicker", {
                                      attrs: {
                                        id: "seminar-date",
                                        state: errors.length > 0 ? false : null,
                                        disabled: _vm.record.isCancel,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.onInputNoticeMinute()
                                        },
                                      },
                                      model: {
                                        value: _vm.recordSub.seminarDate,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.recordSub,
                                            "seminarDate",
                                            $$v
                                          )
                                        },
                                        expression: "recordSub.seminarDate",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "시작 시간",
                            "label-for": "start-time",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "시작 시간",
                              rules: {
                                required: true,
                                before: _vm.recordSub.endTime,
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-date-picker", {
                                      attrs: {
                                        id: "start-time",
                                        type: "time",
                                        format: "HH:mm",
                                        "value-type": "format",
                                        placeholder: "HH:mm",
                                        disabled: _vm.record.isCancel,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.onInputNoticeMinute()
                                        },
                                      },
                                      model: {
                                        value: _vm.recordSub.startTime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.recordSub,
                                            "startTime",
                                            $$v
                                          )
                                        },
                                        expression: "recordSub.startTime",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "종료 시간 (※ 선택 입력 항목입니다.)",
                            "label-for": "start-time",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "종료 시간",
                              rules: {
                                required: false,
                                after: _vm.recordSub.startTime,
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-date-picker", {
                                      attrs: {
                                        id: "start-time",
                                        type: "time",
                                        format: "HH:mm",
                                        "value-type": "format",
                                        placeholder: "HH:mm",
                                        disabled: _vm.record.isCancel,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.onInputNoticeMinute()
                                        },
                                      },
                                      model: {
                                        value: _vm.recordSub.endTime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.recordSub,
                                            "endTime",
                                            $$v
                                          )
                                        },
                                        expression: "recordSub.endTime",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "사전예약 링크",
                            "label-for": "reserve-link",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "사전예약 링크", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "reserve-link",
                                        disabled: _vm.record.isCancel,
                                        state: errors.length > 0 ? false : null,
                                        maxlength: "100",
                                      },
                                      model: {
                                        value: _vm.record.reserveLink,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.record,
                                            "reserveLink",
                                            $$v
                                          )
                                        },
                                        expression: "record.reserveLink",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "시청 링크",
                            "label-for": "watch-link",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "시청 링크", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "watch-link",
                                        state: errors.length > 0 ? false : null,
                                        disabled: _vm.record.isCancel,
                                        maxlength: "100",
                                      },
                                      model: {
                                        value: _vm.record.watchLink,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.record, "watchLink", $$v)
                                        },
                                        expression: "record.watchLink",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "세미나ID",
                            "label-for": "client-seminar-id",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "세미나ID",
                              rules: {
                                required:
                                  _vm.recordSub.isFreepass &&
                                  _vm.recordSub.isPortalReserveApi,
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "client-seminar-id",
                                        disabled:
                                          !_vm.recordSub.isFreepass ||
                                          !_vm.recordSub.isPortalReserveApi ||
                                          _vm.record.isCancel,
                                        state: errors.length > 0 ? false : null,
                                        maxlength: "100",
                                      },
                                      model: {
                                        value: _vm.record.clientSeminarId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.record,
                                            "clientSeminarId",
                                            $$v
                                          )
                                        },
                                        expression: "record.clientSeminarId",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "4" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "강의 취소 여부",
                            "label-for": "is-cancel",
                          },
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: { disabled: _vm.record.isCancel },
                              on: {
                                change: function ($event) {
                                  return _vm.cancelRecord()
                                },
                              },
                              model: {
                                value: _vm.record.isCancel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.record, "isCancel", $$v)
                                },
                                expression: "record.isCancel",
                              },
                            },
                            [_vm._v(" 취소 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-start",
                  attrs: { md: "6" },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "GridIcon", size: "19" },
                  }),
                  _c(
                    "h4",
                    {
                      staticClass: "mb-0 ml-50",
                      staticStyle: { "min-width": "120px" },
                    },
                    [_vm._v(" 시청 팝업 알림 ")]
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "custom-control-info",
                      attrs: {
                        switch: "",
                        inline: "",
                        disabled: _vm.record.isCancel,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.onInputNoticeMinute()
                        },
                      },
                      model: {
                        value: _vm.record.isNoticePopup,
                        callback: function ($$v) {
                          _vm.$set(_vm.record, "isNoticePopup", $$v)
                        },
                        expression: "record.isNoticePopup",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.record.isNoticePopup ? "사용" : "사용안함"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "validation-observer",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.record.isNoticePopup,
                  expression: "record.isNoticePopup",
                },
              ],
              ref: "webSeminarNoticePopupRules",
            },
            [
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "알림 시간 (1)",
                            "label-for": "notice-minute",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "알림 시간 (1)", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-select", {
                                      attrs: {
                                        id: "notice-minute",
                                        options: _vm.codes.noticeMinuteCodes,
                                        placeholder: "선택",
                                        reduce: function (option) {
                                          return option.code
                                        },
                                        state: errors.length > 0 ? false : null,
                                        disabled: _vm.record.isCancel,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.onInputNoticeMinute()
                                        },
                                      },
                                      model: {
                                        value: _vm.record.noticeMinute,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.record,
                                            "noticeMinute",
                                            $$v
                                          )
                                        },
                                        expression: "record.noticeMinute",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "mt-1",
                      staticStyle: { flex: "none", width: "175px" },
                      attrs: { md: "2" },
                    },
                    [
                      _c("b-form-input", {
                        staticStyle: { width: "175px" },
                        attrs: { type: "search", size: "lg", plaintext: "" },
                        model: {
                          value: _vm.recordSub.noticeMinuteDesc,
                          callback: function ($$v) {
                            _vm.$set(_vm.recordSub, "noticeMinuteDesc", $$v)
                          },
                          expression: "recordSub.noticeMinuteDesc",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "알림 시간 (2)",
                            "label-for": "notice-minute",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "알림 시간 (2)",
                              rules: {
                                more: _vm.record.noticeMinute,
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  var failedRules = ref.failedRules
                                  return [
                                    _c("v-select", {
                                      attrs: {
                                        id: "notice-minute2",
                                        options: _vm.codes.noticeMinuteCodes,
                                        placeholder: "선택",
                                        reduce: function (option) {
                                          return option.code
                                        },
                                        state: errors.length > 0 ? false : null,
                                        disabled: _vm.record.isCancel,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.onInputNoticeMinute()
                                        },
                                      },
                                      model: {
                                        value: _vm.record.noticeMinute2,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.record,
                                            "noticeMinute2",
                                            $$v
                                          )
                                        },
                                        expression: "record.noticeMinute2",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              failedRules.hasOwnProperty("more")
                                                ? "알림시간 (2)는 (1)보다 이전이어야 합니다."
                                                : errors[0]
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "mt-1",
                      staticStyle: { flex: "none", width: "175px" },
                      attrs: { md: "2" },
                    },
                    [
                      _c("b-form-input", {
                        staticStyle: { width: "175px" },
                        attrs: { type: "search", size: "lg", plaintext: "" },
                        model: {
                          value: _vm.recordSub.noticeMinuteDesc2,
                          callback: function ($$v) {
                            _vm.$set(_vm.recordSub, "noticeMinuteDesc2", $$v)
                          },
                          expression: "recordSub.noticeMinuteDesc2",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "알림 유지 시간",
                            "label-for": "notice-holding-time",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "알림 유지 시간",
                              rules: "required",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-select", {
                                      attrs: {
                                        id: "notice-holding-time",
                                        options:
                                          _vm.codes.noticeHoldingTimeCodes,
                                        placeholder: "선택",
                                        reduce: function (option) {
                                          return option.code
                                        },
                                        state: errors.length > 0 ? false : null,
                                        disabled: _vm.record.isCancel,
                                      },
                                      model: {
                                        value: _vm.record.noticeHoldingTime,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.record,
                                            "noticeHoldingTime",
                                            $$v
                                          )
                                        },
                                        expression: "record.noticeHoldingTime",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }