<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="query.clientId"
              :options="codes.clients"
              placeholder="제약사"
              :reduce="option => option.code"
              class="el-def"
              style="width: 350px"
            />
            <b-form-input
              v-model="query.title"
              placeholder="제목"
              class="el-def"
              @keyup.enter="fetchData()"
            />
            <!-- <b-form-input
              v-model="query.userName"
              placeholder="작성자"
              class="el-def"
              @keyup.enter="fetchRecords()"
            /> -->
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="query.webSeminarStatus"
              :options="codes.webSeminarStatus"
              placeholder="진행 상태"
              :reduce="option => option.code"
              class="el-def"
            />
            <b-form-datepicker
              v-model="query.startDate"
              class="el-def"
              placeholder="진행기간(Fr)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.endDate"
              class="el-def"
              placeholder="진행기간(To)"
            />
          </b-col>
          <b-col
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="fetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              웹세미나 리스트 [{{ totalRecords }}] 건
            </h5>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-dark"
              class="btn-search"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refSeminarTable"
        primary-key="webSeminarId"
        :items="fetchRecords"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        responsive
        sticky-header="500px"
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onSelectedRecord"
      >
        <template #cell(seminarDate)="data">
          <span class="text-nowrap">
            {{ data.item.startDate | $dateFormatter('YYYY-MM-DD') }}
          </span>
        </template>
        <template #cell(startTime)="data">
          <span class="text-nowrap">
            {{ data.item.startDate | $dateFormatter('HH:mm') }}
          </span>
        </template>
        <template #cell(endTime)="data">
          <span class="text-nowrap">
            {{ data.item.endDate | $dateFormatter('HH:mm') }}
          </span>
        </template>
        <template #cell(isStart)="data">
          <span>
            {{ data.item.isCancel ? '취소' : (data.item.isStart ? '완료' : '예정') }}
          </span>
        </template>
      </b-table>
      <b-pagination
        v-model="currPage"
        :total-rows="totalRecords"
        limit="10"
        per-page="10"
        align="center"
      />
    </b-card>
    <b-card class="mt-2">
      <b-row>
        <b-col
          md="6"
          class="d-flex align-items-center justify-content-start"
        >
          <feather-icon
            icon="EditIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            상세
          </h4>
        </b-col>
        <b-col
          md="6"
          class="d-flex align-items-center justify-content-end"
        >
          <b-button
            variant="outline-primary"
            @click.prevent="resetRecord()"
          >
            <feather-icon
              icon="PlusSquareIcon"
              class="mr-50"
            />
            <span>신규</span>
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            @click="saveRecord()"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span>저장</span>
          </b-button>
          <b-button
            variant="outline-secondary"
            class="ml-1"
            @click.prevent="removeRecord()"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-50"
            />
            <span>삭제</span>
          </b-button>
        </b-col>
      </b-row>
      <validation-observer ref="webSeminarFormRules">
        <b-row class="mt-1">
          <b-col md="3">
            <b-form-group
              label="제약사명"
              label-for="clinet-name"
            >
              <validation-provider
                #default="{ errors }"
                name="제약사명"
                rules="required"
              >
                <v-select
                  id="clinet-name"
                  v-model="record.clientId"
                  :options="codes.clients"
                  :reduce="option => option.code"
                  :state="errors.length > 0 ? false : null"
                  :disabled="record.isCancel"
                  @input="fetchClientInfo()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="포털명"
              label-for="clinet-portal-name"
            >
              <b-form-input
                id="clinet-portal-name"
                v-model="recordSub.clientPortalName"
                :disabled="record.isCancel"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="제목"
              label-for="title"
            >
              <validation-provider
                #default="{ errors }"
                name="제목"
                rules="required"
              >
                <b-form-input
                  id="title"
                  v-model.trim="record.title"
                  :state="errors.length > 0 ? false : null"
                  :disabled="record.isCancel"
                  maxlength="150"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="연자명"
              label-for="lecturer-name"
            >
              <validation-provider
                #default="{ errors }"
                name="연자명"
                rules="required"
              >
                <b-form-input
                  id="lecturer-name"
                  v-model.trim="record.lecturerName"
                  :state="errors.length > 0 ? false : null"
                  :disabled="record.isCancel"
                  maxlength="200"
                  placeholder="연자명을 입력하세요"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="연자소속 병/의원명"
              label-for="lecturer-work-place"
            >
              <b-form-input
                id="lecturer-work-place"
                v-model.trim="record.lecturerWorkPlace"
                :disabled="record.isCancel"
                maxlength="200"
                placeholder="병/의원명을 입력하세요"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="연자 진료과"
              label-for="lecturer-medical-dept"
            >
              <b-form-input
                id="lecturer-medical-dept"
                v-model.trim="record.lecturerMedicalDept"
                :disabled="record.isCancel"
                maxlength="200"
                placeholder="진료과를 입력하세요"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              label="진행 일자"
              label-for="seminar-date"
            >
              <validation-provider
                #default="{ errors }"
                name="진행 일자"
                rules="required"
              >
                <b-form-datepicker
                  id="seminar-date"
                  v-model="recordSub.seminarDate"
                  :state="errors.length > 0 ? false : null"
                  :disabled="record.isCancel"
                  @input="onInputNoticeMinute()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="시작 시간"
              label-for="start-time"
            >
              <validation-provider
                #default="{ errors }"
                name="시작 시간"
                :rules="{ required: true, before: recordSub.endTime }"
              >
                <v-date-picker
                  id="start-time"
                  v-model="recordSub.startTime"
                  type="time"
                  format="HH:mm"
                  value-type="format"
                  placeholder="HH:mm"
                  :disabled="record.isCancel"
                  @input="onInputNoticeMinute()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="종료 시간 (※ 선택 입력 항목입니다.)"
              label-for="start-time"
            >
              <validation-provider
                #default="{ errors }"
                name="종료 시간"
                :rules="{ required: false, after: recordSub.startTime }"
              >
                <v-date-picker
                  id="start-time"
                  v-model="recordSub.endTime"
                  type="time"
                  format="HH:mm"
                  value-type="format"
                  placeholder="HH:mm"
                  :disabled="record.isCancel"
                  @input="onInputNoticeMinute()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="사전예약 링크"
              label-for="reserve-link"
            >
              <validation-provider
                #default="{ errors }"
                name="사전예약 링크"
                rules="required"
              >
                <b-form-input
                  id="reserve-link"
                  v-model="record.reserveLink"
                  :disabled="record.isCancel"
                  :state="errors.length > 0 ? false : null"
                  maxlength="100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="시청 링크"
              label-for="watch-link"
            >
              <validation-provider
                #default="{ errors }"
                name="시청 링크"
                rules="required"
              >
                <b-form-input
                  id="watch-link"
                  v-model="record.watchLink"
                  :state="errors.length > 0 ? false : null"
                  :disabled="record.isCancel"
                  maxlength="100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              label="세미나ID"
              label-for="client-seminar-id"
            >
              <validation-provider
                #default="{ errors }"
                name="세미나ID"
                :rules="{ required : (recordSub.isFreepass && recordSub.isPortalReserveApi) }"
              >
                <b-form-input
                  id="client-seminar-id"
                  v-model="record.clientSeminarId"
                  :disabled="!recordSub.isFreepass || !recordSub.isPortalReserveApi || record.isCancel"
                  :state="errors.length > 0 ? false : null"
                  maxlength="100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group
              label="강의 취소 여부"
              label-for="is-cancel"
            >
              <b-form-checkbox
                v-model="record.isCancel"
                :disabled="record.isCancel"
                @change="cancelRecord()"
              >
                취소
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
    <b-card>
      <b-row>
        <b-col
          md="6"
          class="d-flex align-items-center justify-content-start"
        >
          <feather-icon
            icon="GridIcon"
            size="19"
          />
          <h4
            class="mb-0 ml-50"
            style="min-width: 120px"
          >
            시청 팝업 알림
          </h4>
          <b-form-checkbox
            v-model="record.isNoticePopup"
            switch
            inline
            class="custom-control-info"
            :disabled="record.isCancel"
            @change="onInputNoticeMinute()"
          >
            {{ (record.isNoticePopup ? '사용' : '사용안함') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
      <validation-observer
        v-show="record.isNoticePopup"
        ref="webSeminarNoticePopupRules"
      >
        <b-row class="mt-1">
          <b-col md="3">
            <b-form-group
              label="알림 시간 (1)"
              label-for="notice-minute"
            >
              <validation-provider
                #default="{ errors }"
                name="알림 시간 (1)"
                rules="required"
              >
                <v-select
                  id="notice-minute"
                  v-model="record.noticeMinute"
                  :options="codes.noticeMinuteCodes"
                  placeholder="선택"
                  :reduce="option => option.code"
                  :state="errors.length > 0 ? false : null"
                  :disabled="record.isCancel"
                  @input="onInputNoticeMinute()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="2"
            class="mt-1"
            style="flex: none; width: 175px"
          >
            <b-form-input
              v-model="recordSub.noticeMinuteDesc"
              type="search"
              size="lg"
              plaintext
              style="width: 175px"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              label="알림 시간 (2)"
              label-for="notice-minute"
            >
              <validation-provider
                #default="{ errors, failedRules }"
                name="알림 시간 (2)"
                :rules="{
                  more: record.noticeMinute,
                }"
              >
                <v-select
                  id="notice-minute2"
                  v-model="record.noticeMinute2"
                  :options="codes.noticeMinuteCodes"
                  placeholder="선택"
                  :reduce="option => option.code"
                  :state="errors.length > 0 ? false : null"
                  :disabled="record.isCancel"
                  @input="onInputNoticeMinute()"
                />
                <small class="text-danger">
                  {{
                    failedRules.hasOwnProperty('more')
                      ? '알림시간 (2)는 (1)보다 이전이어야 합니다.'
                      : errors[0]
                  }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="2"
            class="mt-1"
            style="flex: none; width: 175px"
          >
            <b-form-input
              v-model="recordSub.noticeMinuteDesc2"
              type="search"
              size="lg"
              plaintext
              style="width: 175px"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group
              label="알림 유지 시간"
              label-for="notice-holding-time"
            >
              <validation-provider
                #default="{ errors }"
                name="알림 유지 시간"
                rules="required"
              >
                <v-select
                  id="notice-holding-time"
                  v-model="record.noticeHoldingTime"
                  :options="codes.noticeHoldingTimeCodes"
                  placeholder="선택"
                  :reduce="option => option.code"
                  :state="errors.length > 0 ? false : null"
                  :disabled="record.isCancel"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import vSelect from 'vue-select'
import {
  ref, onMounted, watch, getCurrentInstance,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { saveAs } from "file-saver"

export default {
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    onMounted(() => {
      fetchCodes()
    })

    const instance = getCurrentInstance()
    const bvModal = instance.proxy.$bvModal
    const refs = instance.proxy.$refs

    const webSeminarId = ref(null)
    const recordInitState = {
      title: null,
      lecturerName: null,
      lecturerWorkPlace: null,
      lecturerMedicalDept: null,
      startDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      endDate: null,
      reserveLink: null,
      watchLink: null,
      isCancel: false,
      clientId: null,
      clientSeminarId: null,
      isNoticePopup: true,
      noticeMinute: 10,
      noticeMinute2: null,
      noticeHoldingTime: 10,
    }

    const record = ref({ ...recordInitState })

    const recordSubInitState = {
      clientPortalName: null,
      isFreepass: null,
      isPortalReserve: null,
      isPortalReserveApi: null,
      seminarDate: dayjs().format('YYYY-MM-DD'),
      startTime: dayjs().format('HH:mm'),
      endTime: null,
      noticeMinuteDesc: null,
      noticeMinuteDesc2: null,
    }

    const recordSub = ref({ ...recordSubInitState })

    const resetRecord = () => {
      webSeminarId.value = null

      Object.assign(record.value, recordInitState)
      Object.assign(recordSub.value, recordSubInitState)

      refs.webSeminarFormRules.reset()
      refSeminarTable.value.clearSelected()

      onInputNoticeMinute()
    }

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon

      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    // Set Codes
    const codes = ref({
      webSeminarStatus: [],
      clients: [],
      noticeMinuteCodes: [
        { label: "5분전", code: 5 },
        { label: "10분전", code: 10 },
        { label: "20분전", code: 20 },
        { label: "30분전", code: 30 },
        { label: "1시간전", code: 60 },
        { label: "1일전", code: 1440 },
        { label: "3일전", code: 4320 },
        { label: "7일전", code: 10080 },
      ],
      noticeHoldingTimeCodes: [
        { label: "10초", code: 10 },
        { label: "30초", code: 30 },
        { label: "1분", code: 60 },
        { label: "5분", code: 300 },
      ],
    })

    const fetchCodes = () => {
      axios.get('/fa/webseminar/codes')
        .then(rs => {
          const {
            webSeminarStatus,
            clients,
          } = rs.data

          codes.value.webSeminarStatus = webSeminarStatus
          codes.value.clients = clients
        })
        .catch(() => {
          pushToast('danger', '코드 데이터를 불러오는데 실패하였습니다.')
        })
    }
    // Set Codes End.

    // Query Data
    const query = ref({
      clientId: null,
      title: null,
      userName: null,
      webSeminarStatus: null,
      startDate: null,
      endDate: null,
    })
    // Query Data End.

    // Main Table Config
    const refSeminarTable = ref(null)
    const records = ref([])
    const sortBy = ref('webSeminarId')
    const sortDesc = ref(true)
    const totalRecords = ref(0)
    const currPage = ref(1)
    const tableColumns = [
      {
        key: 'webSeminarId', label: 'Id', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'clientName', label: '제약사명', sortable: true, thStyle: { width: '10%' },
      },
      { key: 'title', label: '제목', sortable: true },
      {
        key: 'seminarDate', label: '진행일', sortable: true, thStyle: { width: '10%' },
      },
      {
        key: 'startTime', label: '시작시간', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'endTime', label: '종료시간', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'isStart', label: '진행상태', sortable: false, thStyle: { width: '7%' },
      },
    ]

    watch([currPage], () => {
      refSeminarTable.value.refresh()
    })

    const fetchData = () => {
      webSeminarId.value = null

      refSeminarTable.value.refresh()
    }

    const onSelectedRecord = items => {
      const item = items[0]

      if (item && item.webSeminarId) {
        fetchRecord(item.webSeminarId)
      }
    }
    // Main Table Config End.

    const onInputNoticeMinute = () => {
      if (record.value.noticeMinute && recordSub.value.seminarDate && recordSub.value.startTime) {
        const startDate = `${recordSub.value.seminarDate} ${recordSub.value.startTime}`
        recordSub.value.noticeMinuteDesc = dayjs(startDate).subtract(record.value.noticeMinute, 'minute').format('YYYY-MM-DD HH:mm:ss')
      } else {
        recordSub.value.noticeMinuteDesc = null
      }

      if (record.value.noticeMinute2 && recordSub.value.seminarDate && recordSub.value.startTime) {
        const startDate = `${recordSub.value.seminarDate} ${recordSub.value.startTime}`
        recordSub.value.noticeMinuteDesc2 = dayjs(startDate).subtract(record.value.noticeMinute2, 'minute').format('YYYY-MM-DD HH:mm:ss')
      } else {
        recordSub.value.noticeMinuteDesc2 = null
      }
    }

    const initPopupNotice = () => {
      record.value.noticeMinute = null
      record.value.noticeHoldingTime = null

      recordSub.value.noticeMinuteDesc = null
    }

    const fetchClientInfo = () => {
      if (record.value.clientId !== null) {
        axios.get(`/fa/client/${record.value.clientId}`)
          .then(rs => {
            recordSub.value.clientPortalName = rs.data.clientPortalName
            recordSub.value.isFreepass = rs.data.isFreepass
            recordSub.value.isPortalReserve = rs.data.isPortalReserve
            recordSub.value.isPortalReserveApi = rs.data.isPortalReserveApi

            refs.webSeminarFormRules.reset()
          })
          .catch(() => {
            pushToast('danger', '포탈명 조회 중 오류가 발생하였습니다.')
          })
      } else {
        recordSub.value.clientPortalName = null
        recordSub.value.isPortalReserve = null
        recordSub.value.isPortalReserveApi = null
      }
    }

    const fetchRecord = id => {
      axios.get(`/fa/webseminar/${id}`)
        .then(rs => {
          webSeminarId.value = id

          record.value = rs.data

          Object.assign(recordSub.value, recordSubInitState)

          recordSub.value.seminarDate = dayjs(rs.data.startDate).format('YYYY-MM-DD')
          recordSub.value.startTime = dayjs(rs.data.startDate).format('HH:mm')
          recordSub.value.endTime = rs.data.endDate ? dayjs(rs.data.endDate).format('HH:mm') : null

          if (rs.data.isNoticePopup) {
            onInputNoticeMinute()
          }

          fetchClientInfo()
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')
        })
    }

    const fetchRecords = (ctx, callback) => {
      axios.post('/fa/webseminar/list', {
        search: {
          clientId: query.value.clientId,
          title: query.value.title,
          userName: query.value.userName,
          webSeminarStatus: query.value.webSeminarStatus,
          startDate: query.value.startDate === '' ? null : query.value.startDate,
          endDate: query.value.endDate === '' ? null : query.value.endDate,
        },
        sort: {
          predicate: sortBy.value,
          reverse: sortDesc.value,
        },
        pagination: {
          number: currPage.value,
          count: 10,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data

          callback(items)

          totalRecords.value = totalRecord

          if (webSeminarId.value === null) resetRecord()
          else {
            const row = items.findIndex(x => x.webSeminarId === webSeminarId.value)

            setTimeout(() => {
              refSeminarTable.value.selectRow(row)
            }, 100)
          }
        })
        .catch(() => {
          pushToast('danger', '데이터 조회 중 오류가 발생하였습니다.')

          callback(null)
        })
    }

    const saveRecord = () => {
      const validations = []

      validations.push(refs.webSeminarFormRules.validate())

      if (record.value.isNoticePopup) {
        validations.push(refs.webSeminarNoticePopupRules.validate())
      } else {
        record.value.noticeMinute = null
        record.value.noticeHoldingTime = null
      }

      Promise.all(validations).then(valids => {
        const result = valids.every(Boolean)

        if (!result) {
          pushToast('danger', '입력하지 않았거나, 유효하지 않은 데이터가 있습니다.')
        } else if (checkLeturer()) {
          record.value.startDate = `${recordSub.value.seminarDate} ${recordSub.value.startTime}:00`

          if (recordSub.value.endTime) record.value.endDate = `${recordSub.value.seminarDate} ${recordSub.value.endTime}:00`
          else record.value.endDate = null

          if (webSeminarId.value) updateRecord()
          else createRecord()
        }
      })
    }

    const checkLeturer = () => {
      if (record.value.lecturerWorkPlace !== null && record.value.lecturerWorkPlace.length > 0 && record.value.lecturerName.split(',').length !== record.value.lecturerWorkPlace.split(',').length) {
        pushToast('danger', '연자 정보의 구분자 정보가 일치하지 않습니다.')

        return false
      }

      if (record.value.lecturerMedicalDept !== null && record.value.lecturerMedicalDept.length > 0 && record.value.lecturerName.split(',').length !== record.value.lecturerMedicalDept.split(',').length) {
        pushToast('danger', '연자 정보의 구분자 정보가 일치하지 않습니다.')

        return false
      }

      if (record.value.lecturerName.split(',').length > 10) {
        pushToast('danger', '연자 정보의 구분자 정보는 최대 10명입니다.')

        return false
      }

      return true
    }

    const createRecord = () => {
      axios.post('/fa/webseminar', record.value)
        .then(() => {
          refSeminarTable.value.refresh()
        })
        .catch(() => {
          pushToast('danger', '데이터 저장 중 오류가 발생하였습니다.')
        })
    }

    const updateRecord = () => {
      axios.put(`/fa/webseminar/${webSeminarId.value}`, record.value)
        .then(() => {
          pushToast('success', '저장하였습니다.')

          refSeminarTable.value.refresh()
        })
        .catch(error => {
          pushToast('danger', error)
        })
    }

    const cancelRecord = () => {
      if (webSeminarId.value === null) {
        pushToast('warning', '취소할 대상을 선택하세요.')

        record.value.isCancel = false
      } else {
        bvModal
          .msgBoxConfirm('해당 세미나를 취소하시겠습니까?', {
            size: 'sm',
            cancelVariant: 'outline-secondary',
            centered: true,
            okTitle: '네',
            cancelTitle: '아니오',
          })
          .then(confirm => {
            if (confirm) {
              axios.put(`/fa/webseminar/${webSeminarId.value}/cancel`, {
                isCancel: record.value.isCancel,
              })
                .then(() => {
                  pushToast('success', '취소하였습니다.')

                  refSeminarTable.value.refresh()
                })
                .catch(() => {
                  record.value.isCancel = !record.value.isCancel

                  pushToast('danger', '세미나 취소 중 오류가 발생하였습니다.')
                })
            } else record.value.isCancel = !record.value.isCancel
          })
      }
    }

    const removeRecord = () => {
      if (webSeminarId.value === null) {
        pushToast('warning', '삭제할 대상을 선택하세요.')

        return
      }

      bvModal
        .msgBoxConfirm('삭제 하시겠습니까?', {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) {
            return
          }

          axios.delete(`/fa/webseminar/${webSeminarId.value}`)
            .then(() => {
              pushToast('success', '삭제하였습니다.')

              refSeminarTable.value.refresh()
            })
            .catch(() => {
              pushToast('danger', '데이터 삭제 중 오류가 발생하였습니다.')
            })
        })
    }

    const excelDownload = () => {
      axios.post('/fa/webseminar/list-excel', {
        search: {
          clientId: query.value.clientId,
          title: query.value.title,
          userName: query.value.userName,
          webSeminarStatus: query.value.webSeminarStatus,
          startDate: query.value.startDate === '' ? null : query.value.startDate,
          endDate: query.value.endDate === '' ? null : query.value.endDate,
        },
        sort: {
          predicate: sortBy.value,
          reverse: sortDesc.value,
        },
      }, { responseType: 'blob' })
        .then(rs => {
          const blob = new Blob([rs.data])
          const fileName = '웹세미나_리스트'

          saveAs(blob, `${fileName}.xlsx`)
        })
        .catch(() => {
        })

      // const workbook = new ExcelJS.Workbook()
      // const worksheet = workbook.addWorksheet("sheet1")

      // worksheet.columns = [
      //   { header: "Id", key: "webSeminarId", width: 10 },
      //   { header: "제약사명", key: "clientName", width: 20 },
      //   { header: "제목", key: "title", width: 40 },
      //   { header: "진행일", key: "seminarDate", width: 12 },
      //   { header: "시작시간", key: "startTime", width: 10 },
      //   { header: "종료시간", key: "endTime", width: 10 },
      //   {
      //     header: "진행상태",
      //     key: "isStart",
      //     width: 10,
      //   },
      // ]

      // refSeminarTable.value.localItems.map(e => worksheet.addRow({
      //   webSeminarId: e.webSeminarId,
      //   clientName: e.clientName,
      //   title: e.title,
      //   seminarDate: dayjs(e.startDate).format('YYYY-MM-DD'),
      //   startTime: dayjs(e.startDate).format('HH:mm'),
      //   endTime: e.endDate === null ? '' : dayjs(e.endDate).format('HH:mm'),
      //   isStart: e.isCancel ? '취소' : (e.isStart ? '완료' : '예정'),
      // }))

      // worksheet.eachRow({ includeEmpty: true }, (row, number) => {
      //   row.eachCell({ includeEmpty: true }, cell => {
      //     if (number === 1) {
      //       // eslint-disable-next-line no-param-reassign
      //       cell.font = {
      //         bold: true,
      //       }

      //       // eslint-disable-next-line no-param-reassign
      //       cell.fill = {
      //         type: 'pattern',
      //         pattern: 'solid',
      //         fgColor: { argb: 'D3D3D3' },
      //       }
      //     }

      //     // eslint-disable-next-line no-param-reassign
      //     cell.border = {
      //       top: { style: 'thin' },
      //       left: { style: 'thin' },
      //       bottom: { style: 'thin' },
      //       right: { style: 'thin' },
      //     }
      //   })
      // })

      // const mimeType = {
      //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // }

      // const buffer = await workbook.xlsx.writeBuffer()

      // const blob = new Blob([buffer], mimeType)

      // saveAs(blob, "웹세미나_리스트.xlsx")
    }

    return {
      webSeminarId,
      codes,
      query,
      record,
      recordSub,
      records,
      tableColumns,
      totalRecords,
      currPage,
      fetchRecords,
      onSelectedRecord,
      fetchClientInfo,
      onInputNoticeMinute,
      resetRecord,
      saveRecord,
      createRecord,
      updateRecord,
      cancelRecord,
      removeRecord,
      required,
      refSeminarTable,
      fetchData,
      sortBy,
      sortDesc,
      excelDownload,
      initPopupNotice,
      checkLeturer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style>
.ql-container.ql-snow {
  height: auto;
}
.ql-editor {
  height: 320px;
  overflow-y: scroll;
}
</style>
